.quote{
    width:100%;
}

.quote .quoteh1, .quote .quoteh2 {
  text-align: center;
  background-color: rgba(42,42,42,0.8);
  width:fit-content;
  margin-left:auto;
  margin-right:auto;
  padding:10px;
}

.formContent {
  width: 100%;
  color:var(--box-background-color);
}

.quoteContent {
    width:100%;
}

/*Go To education.css for "option" */

.backButton {
    background-color: var(--background-color-grey);
    border-top-left-radius:5px;
    border-top-right-radius:5px;
}

.backButton button {
    margin:10px;
    border-radius:0px;
    border:solid var(--primary-color) 2px;

}

.quoteHeader {
    background-color: var(--background-color-grey);
    color:var(--primary-color);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius:5px;
    text-align: center;
}

.quoteContent .container1 {
    border-radius:0px;
}

.quoteContent .container2 {
    border-radius:0px;
}

.questionContainer {
    width:90%;
    margin:auto;
}

.select {
    display:flex;
    flex-flow:column;
    justify-content: space-around;
    align-items: center;
    
}

.select select {
    width:50%;
    min-width:200px;
    height:40px;
    text-align: center;
    font-weight:bold;
    font-size:large;
    border-radius: 0px;
    border-style:solid;
    border-width:3px;
    border-color:black;
}

.multipleChoice .choices {
    display:flex;
    flex-flow:row;
    width:70%;
    margin:auto;
    justify-content: space-evenly;
    border-radius: 0px;
    border:3px solid;
}

.multipleChoice label {
    display:flex;
    flex-flow:row;
    justify-content: center;
    align-items: center;
}

.multipleChoiceWithImages {
    display:flex;
    flex-flow:row;
    justify-content: space-evenly;
}



/* For actual checkboxes */
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    border: 2px solid #ccc;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    transition: background-color 0.3s;
}

.custom-checkbox:checked {
    background-color: blue;
}

.custom-checkbox:checked::after {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px; 
    color: white;
}

.custom-checkbox:hover {
    background-color: gray;
}

/* For radios styled like checkboxes */
.hidden-radio {
    display: none;  /* Hide the actual radio input */
}

.custom-checkbox-label {
    display: inline-block;
    width: 30px;  
    height: 30px;
    border: 1px solid gray;
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 0px;
}

.hidden-radio:checked + .custom-checkbox-label {
    background-color: blue;
}

.hidden-radio:checked + .custom-checkbox-label::after {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px; 
    color: white;  
}

.custom-checkbox-label:hover {
    background-color: gray;
}

.RadioOptions {
    display:flex;
    flex-flow:row;
    width:80%;
    justify-content: space-around;
    margin:auto;
}

.optionContainer {
    display:flex;
    flex-flow:column;
    justify-content: flex-start;
    align-items: center;
    width:40%;
    height:fit-content;
    transition: padding 0.3s, background-color 0.3s ease;
    border-radius: 0px;
    border:3px solid;
    background-color: var(--box-background-color);
}

.optionContainer.clicked {
    padding: 10px;
    background-color:rgba(0, 0, 255, .75);
    color:white;
    border-color:var(--primary-color);
}

.optionContainer.clicked img {
    border-color: var(--accent-color);
    border: solid 3px;
}

.optionContainer.clicked:hover {
    background-color: rgba(0, 0, 255, 0.7);
}

.optionContainer img {
    max-height:300px;
    margin-top:5px;    
    width:100%;
    max-width:350px;
    border-radius: 0px;
    border:3px var(--primary-color) solid;
}

.optionContainer:hover {
    cursor: pointer;
    background-color: gray;
}

.offGridBatteries {
    justify-content: space-around;
    align-items: center;
}

.offGridBatteries .optionContainer {
    width:300px;
}

.offGridBatteries img {
    width:50%;
    border:none;
}

.groundOrRoof {
    display:flex;
    flex-flow: row;
    justify-content: space-around;
}


.numInput {
    width:300px;
    height:24px;
    border:solid var(--primary-color);
    border-radius:0px;
    font-size: x-large;
}

.linkBox {
    background-color: var(--box-background-color);
    width:30%;
    text-align: center;
    border-radius:0px;
    margin-top:20px;
}

.recommendation {
    width:100%;
    display:flex;
    justify-content: center;
}

.recommendation button:hover {
    background-color: gray;
    border-color:black;
}

.followUpInput {
    width:75%;
    max-width:500px;
    height:36px;
    border-radius: 0px;
    border-width:5px;
    border-color:black;
    font-size: x-large;
    text-align: center;
    font-weight:bold;
}

.installation {
    justify-content: space-around;
    align-items: center;
}

.installation .optionContainer {
    width:300px;
    text-align: center;
}

.installation img {
    width:50%;
    border:none;
}

select {
    background-color: white;
    transition:background-color 0.3s ease;
}

select:hover {
    background-color: bisque;
}

.addressContainer {
    display:flex;
    flex-flow:column;
    justify-content: center;
    align-items: center;
    width:100%;
}

.addressParameter {
    margin:5px;
    width:100%;
    box-sizing: border-box;
    display:flex;
    flex-flow:row;
    align-items: center;
    justify-content: center;
}

.addressParameter label {
    min-width:150px;
}

.addressParameter input {
    width:40%;
    margin-left:5%;
    height:24px;
    border-radius: 0px;
}

.quoteRequestButton {
  border:solid 2px var(--primary-color);
  border-radius:0px;
  width:10%;
  margin:auto;
  position:fixed;
  bottom:70px;
  right:20px;
}

.inputContainer {
    margin:auto;
    display:flex;
    flex-flow:column;
    align-items: flex-start;
    justify-content: flex-start;
}

.textInputContainer {
    display:flex;
    flex-flow:column;
    justify-content: center;
    align-items: center;
    margin:auto;
}

.textInput {
    width:40%;
    margin-left:5%;
    height:24px;
    border-radius: 0px;
    font-weight:bold;
    font-size:24px;
}

.textInputForAh {
    width:20%;
    margin-left:5%;
    height:24px;
    border-radius: 0px;
    font-weight:bold;
    font-size:24px;
    text-align: right;
}

.disclaimer p {
    padding:0;
}

.breakerRadioContainer {
    display:flex;
    flex-flow:row;
    justify-content: space-evenly;
}

.recommendationForNewBatteries{
    margin-bottom: 0;
}

.batteryRecommendation {
    display:flex;
    flex-flow:row;
    justify-content: space-around;
    align-items: flex-start;
    
}

.batteryRecommendation h4 {
    width:30%;
    min-height:80px;
    border:solid 3px;
    border-radius: 0px;
    background-color:var(--box-background-color);
    text-align: center;
}

.inputContainer textarea {
    width:80%;
    border-radius: 0px;
    border:solid 3px black;
    height:150px;
    margin:auto;
    text-align: start;
    resize:none;
    font-size:large;
}

.charCount {
    text-align: right;
    width:80%;
    font-size: large;
    color:gray;
    height:20px;
    padding:0;
}

.quoteConfirmation {
  background-color: var(--background-color-grey);
  color:black;
}

@media only screen and (max-width:600px) {
    .quoteHeader{
        font-size: large;
    }

    .select select {
        width:80%;
    }

    .questionContainer h2 {
        padding:0;
        margin-top:1rem;
        margin-bottom:0;
    }

    .multipleChoice .choices {
        flex-flow:column;
        width:60%;
    }

    .multipleChoiceWithImages {
        flex-flow:column;
    }

    .optionContainer {
        flex-flow:row;
        width:95%;
        margin-bottom:1rem;
    }

    .optionContainer img {
        width: 30%;
        height:auto;
    }

    .optionContainer h3 {
        width:70%;
    }

    .linkBox {
        width:75%;
    }

    .quoteRequestButton {
        width:60%;
    }
}