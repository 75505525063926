.educationPage .educationPageH1, .educationPage .educationPageH2 {
  color:white;
  background-color: rgba(42,42,42,0.8);
  width:fit-content;
  margin-left:auto;
  margin-right:auto;
  padding:10px;
}

.container1 {
  width:100%;
  background-color: var(--box-background-color);
  padding-bottom:24px;
  color:black;
  border-radius: 0px;
  font-size: x-large;
}

.container2 {
  width:100%;
  background-color: var(--background-color-grey);
  padding-bottom:24px;
  color:black;
  border-radius: 0px;
  font-size: x-large;
}

.options .active {
  background-color: var(--secondary-color);
}

.options {
  display:flex;
  flex-flow:row wrap;
  justify-content: space-around;
  
  padding-bottom:20px;
  border-radius: 0px;
  background-color: rgba(42,42,42,0.8);
}

.options a {
  text-decoration: none;
  color:white;
  line-height:5px;
  width:35%;
  max-width:350px;
  min-width:300px;
  border-radius: 0px;
  margin-top:20px;
  padding:10px;
  transition:background-color 0.3s ease, transform 0.3s ease-in-out;
}

.container1 .options {
  margin:auto;
  width:80%;
  border-color:var(--background-color);
}

.container2 .options {
  margin:auto;
  width:80%;
  border-color: var(--background-color);
}

.option {
  display:flex;
  flex-flow:column wrap;
  align-items: center;
  justify-content: flex-start;
  transition: transform 0.1s ease-in-out;
}

.options a:hover {
  transform: scale(1.025);
  cursor:pointer;
  color:black;
  background-color: var(--secondary-color);
}

.container1 .options a {
  border-color:var(--background-color);
}

.container2 .options a {
  border-color:var(--background-color);
}

.option img {
  width:100%;
  max-height:250px;
  padding:0;
  margin:0;
  border-style:solid;
  border-color:black;
  border-radius: 0px;
}

.doubleContainer {
  display:flex;
  flex-flow:row;
  justify-content: space-evenly;
}

.leftContainer {
width:44%;
height:fit-content;
display:flex;
flex-flow: column;
align-items: center;
border:solid 5px;
border-radius:0px;
background-color:var(--box-background-color);
}

.rightContainer {
width:44%;
height:fit-content;
display:flex;
flex-flow: column;
align-items: center;
border:solid 5px;
border-radius:0px;
background-color:var(--box-background-color);
}

/*For SolarPanel.js */

.bannerContainer {
  display:flex;
  flex-flow:column;
  margin:auto;
  padding:0;
  width:100%;
}

.banner1SP {
  display:flex;
  flex-flow:column;
  justify-content: center;
  text-align: center;
  width:90%;
  margin:auto;
}

.banner1SP img {
  width:40%;
  min-width:410px;
  height:100%;
  margin:10px;
  border:solid 3px;
  border-radius:0px;
}

.banner1SP .explanation {
  display:flex;
  flex-flow:column;
  max-height:550px;
  min-width:425px;
  width:40%;
  overflow: auto;
  border:solid 3px;
  border-radius: 0px;
  align-items: flex-start;
  margin:10px;
  padding-right:5px;
  padding-left:5px;
}

.banner1SP .solarInfo {
  display:flex;
  flex-flow:row wrap;
  justify-content: space-evenly;
  text-align: start;
}

.banner2SP {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin:0;
}

.banner2SP h1{
  text-align: center;
}

.banner2SP img {
  width:200px;
  height:300px;
  padding:10px;
  background-color: var(--box-background-color);
  border:solid 3px;
  border-radius: 0px;
}

.banner2SP ul {
  display:flex;
  flex-flow:column;
  width:80%;
  align-items:flex-start;
}

.banner3SP h1 {
  text-align: center;
}

.banner3SP p {
  text-align: left;
}

.banner4SP h1 {
  text-align: center;
}

/*For GridTied.js */

.gridTiedTitle {
  color:black;
  text-align: center;
}


.banner1GT {
  width:90%;
  height:auto;
  display:flex;
  flex-flow:row wrap;
  margin:auto;
  font-size:x-large;
  justify-content: center;
}

.banner1GT h1 {
  color:black;
}

.banner1GT .leftContainer {
  width:55%;
}

.banner1GT .rightContainer {
  width: 40%;
  height:100%;
  display:flex;
  flex-flow:row wrap;
  justify-content: center;
  align-items: flex-start;
  border:none;
}

.banner1GT img {
  width:100%;
  max-height:300px;
  padding:5px;
  border-style:solid;
  background-color: white;
  margin-top:10px;
  border-radius:0px;
}

.banner2GT {
  width:99%;
  display:flex;
  flex-flow:column;
  margin:auto;
  justify-content: center;
  align-items: center;
}

.banner2GT ul {
  display: flex;
  flex-flow:column;
  justify-content: flex-start;
  align-items: flex-start;
  width:90%
}

.banner2GT h1{
  width:100%;
  color:black;
  text-align: center;
}

.banner2GT img {
  width:100%;
  min-width:200px;
  max-width:350px;
  max-height:300px;
  min-height:300px;
  padding:5px; 
  border-style:solid;
  background-color: white;
  border-radius:0px;
}

.banner3GT {
  display:flex;
  height:auto;
  display:flex;
  flex-flow:column wrap;
  margin:auto;
  justify-content: center;
  align-items: center;
}

.banner3GT h1 {
  color:black;
}

.banner3GT img {
  max-width:60%;
  width:40%;
  align-self: center;
  border-radius: 0px;
}

.banner3GT figcaption {
  text-align: center;
}

.banner3GT img:hover{
  transform: scale(1.0);
}

.banner3GT h2, .banner3GT h3 {
  text-align: center;
  width:95%;
}

.banner4GT {
  display:flex;
  flex-flow:column wrap;
  width:100%;
}

.banner4GT h1 {
  text-align: center;
  color:black;
}

.product {
  display:flex;
  flex-flow:row wrap;
  justify-content: space-around;
  align-items: flex-start;
  margin-top:20px;
  border:solid 3px;
  align-items: center;
  width:90%;
  margin-left:auto;
  margin-right:auto;
  background-color: aliceblue;
  border-radius:0px;
}

.product img {
  min-width:200px;
  width:300px;
  max-width:450px;
  height:300px;
  background-color: rgb(var(--background-color-base));
  border-radius:0px;
}

.descriptionGT {
  width:60%;
}

.banner5GT {
  text-align: center;
}

.banner6GT {
  color:black;
  text-align: center;
  margin:auto;
}

.BGT {
  width:90%;
  margin:auto;
}
.BGT h1, .BGT h2 {
  text-align: center;
}

.banner2BGT {
  display:flex;
  flex-flow:column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.banner2BGT img {
  width:40%;
  border-radius: 0px;
}

.banner3BGT .section {
  display:flex;
  flex-flow:row;
  width:100%;
  margin-bottom:30px;
}

.banner3BGT .section section {
  display:flex;
  flex-flow:column;
  align-items: flex-start;
  width:70%;
  margin-right:5%;
  padding-left:5px;
  padding-right:5px;
}

.banner3BGT .section img {
  width:30%;
  border-radius:0px;
  margin:auto;
}

.banner5BGT {
  display:flex;
  flex-flow:column;
}

.banner5BGT .section {
  display:flex;
  flex-flow:row wrap;
  justify-content: space-between;
}

.banner5BGT .section section {
  display: flex;
  flex-flow:column;
  align-items: flex-start;
  width:50%;
}

.banner5BGT img {
  width:45%; 
  max-height:800px;
}

.banner1OG {
  width:90%;
  margin:auto;
}

.banner1OG ul {
  list-style-type: none;
}

.banner1OG ul li {
  display:flex;
  flex-flow:column;
  align-items: flex-start;
}

.banner1OG h1 {
  text-align: center;
}

.banner3OG img:hover {
  transform: scale(1.0);
}

.banner3OG h1 {
  text-align: center;
}

.banner3OG .figures {
  display:flex;
  flex-flow:row;
  width:90%;
  margin:auto;
  align-items: flex-start;
  justify-content: space-evenly;
}

.banner3OG .figures .figure {
  display:flex;
  flex-flow:column;
  justify-content: center;
  align-items: center;
  width:45%;
}

.banner3OG .figures .figure img {
  width:100%;
  border-radius: 0px;
  max-height: fit-content;
}

.banner3OG .needWhiteBackground img{
  background-color: #FFFFFF;
  padding-top:45px;
  padding-bottom:45px;
}

.banner4OG ul {
  list-style-type: none;
}

.banner4OG h1 {
  text-align: center;
}

.banner4OG h2 {
  text-decoration: underline;
}

.banner4OG .leftContainer, .banner4OG .rightContainer {
  border-style:solid;
  border-radius:0px;
  width:45%;
  margin:auto;
  margin-top:0;
}

.banner1RV {
  display:flex;
  flex-flow:column;
  align-items: center;
  justify-content: center;
}

.banner1RV ul {
  font-size:xx-large;
}

.banner1RV img{
  width:60%;
  max-width:400px;
  max-height:350px;
  border-radius: 0px;
}

.banner1RV .mainPic {
  width:60%;
  max-width: fit-content;
  max-height:fit-content;
}

.banner1RV img:hover{
  transform:scale(1.0);
}

.banner1RV .rightContainer {
  display:flex;
  height:auto; 
  flex-flow:column;
  align-items: center;
  justify-content: space-around;
  border:none;
}

.banner2RV p, .banner2RV h3 {
  font-size:xx-large;
}

.banner2RV {
  width:90%;
  margin:auto;
}

.banner3RV {
  width:90%;
  margin: auto;
}

.banner3RV ul {
  padding:0;
  margin:auto;

}

.banner3RV li {
  list-style-type: none;
  font-size:xx-large;
}

.banner3RV .comparison {
  display:flex;
  flex-flow:row;
  align-items: center;
  justify-content: space-around;
  margin:auto;
  padding:0;
}

.banner3RV .compareSection {
  border-style:solid;
  display:flex;
  flex-flow:column wrap;
  justify-content: center;
  align-items: center;
  width:40%;
  padding:15px;
  border-radius:0px;
  border-width:3px;
  margin-top:20px;
  margin-bottom:20px;
  background-color:var(--accent-color);
}

.banner3RV .compareSection h3 {
  margin-top:5px;
  margin-bottom:5px;
  text-align: center;
}

.banner3RV img {
width:70%;
border-radius:0px;
border:3px solid;
background-color:var(--box-background-color);
}

.banner4RV {
  display:flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width:95%;
  align-items: center;
  margin:auto;
}

.banner4RV img {
background-color: var(--secondary-color);
border:solid 3px;
border-radius:0px;
padding-left:10%;
padding-right:10%;
}

.banner4RV .imagesRV {
  display:flex;
  flex-flow:row wrap;
  justify-content: space-around;
  align-items: center;
}

.banner4RV .imagesRV img {
  width:40%;
  padding:20px;
}

.descriptionRV {
  display:flex;
  flex-flow:column;
  height:100%;
  align-content: space-between;
}

.banner5RV img:hover {
  transform: scale(1.0);
}

.banner5RV .imagesRV {
  display:flex;
  flex-flow:row;
  justify-content: space-evenly;
  align-items: center;

}

.banner5RV .imagesRV img {
  width:40%;
  border-style:solid;
  border-width: 3px;
  border-radius:0px;
  background-color: var(--accent-color);
}

.banner5RV .imageRV {
  display:flex;
  flex-flow:column;
  justify-content: center;
  align-items: center;
  border-radius:0px;
  border:solid 3px;
  width:90%;
  margin:auto;
  padding-top:20px;
  background-color: var(--accent-color);
  margin-top:20px;
  margin-bottom:20px; 
}

.banner5RV .imageRV img {
  width:75%;

}

.banner6RV {
  line-height: 30px;
}

.banner6RV img {
  border-radius: 0px;
  height:200px;
  object-fit: cover;
}

.banner6RV img:hover{
  transform:scale(1.0);
}

.Victron{
  display:flex;
  flex-flow:column;
  justify-content: center;
  align-items: center;
  width:100%;
  height:50%;
}

.Victron img {
  width:clamp(400px, 500px, 80%)
}

.backToTop {
  position: fixed;
  float:right;
  z-index:100;
  color:black;
  width:150px;
  right: 20px;
  bottom: 20px;
  border-radius: 0px;
  border:solid 2px var(--primary-color);
}

@media only screen and (max-width:1100px) {
  .banner1GT {
      width:100%;
  }

  .banner5BGT .section section {
      width:90%;
  }
  .banner5BGT img {
      display:none;
  }
  .descriptionGT {
      width:90%;
  }

}

@media only screen and (max-width:930px) {


  .banner2GT img {
      max-width:250px;
  }
}

@media only screen and (max-width: 600px) {
  .container1 {
      font-size: large;
  }

  .container1 h1 {
      font-size: xx-large;
      text-decoration: underline;
  }

  .container2 {
      font-size: large;
  }

  .container2 h1 {
      font-size: xx-large;
      text-decoration: underline;
  }

  .container1 h1 {
      margin-bottom: 0;
  }

  .container2 h1 {
      margin-bottom: 0;
  }

  .banner1SP img {
      min-width: 80%;
      height:auto;
      border: 3px solid black;
  }

  .banner1SP .explanation {
      min-width: 100%;
      border:solid 3px black;
  }

  .banner2SP img {
      width:50%;
      height:auto;
  }

  .banner2SP .doubleContainer {
      display:flex;
      flex-flow:column;
  }

  .banner2SP li {
      margin-bottom:10px;
  }

  .banner2SP li p {
      padding:0;
      text-align: left;
  }

  .banner2SP .leftContainer {
      width:90%;
      margin:auto;
      margin-bottom:2rem;
      border:3px black solid;
  }

  .banner2SP .rightContainer {
      width: 90%;
      margin:auto;
      border:3px black solid;
  }

  .options {
      flex-flow:column;
      height: auto;
      border:none;
      justify-content: space-evenly;
  }

  .options a {
      height:fit-content;
      line-height: normal;
      width:90%;
      margin: auto;
      text-align: center;
  }

  .option {
      flex-flow:row;
  }

  .option img {
      width:40%;
      height:auto;
      border-radius: 0px;
  }

  .option h3, .option h2 {
      width:100%;
  }

  .banner1GT .rightContainer {
      display:none;
  }

  .banner1GT .leftContainer {
      width:95%;
      border:3px black solid;
  }

  .banner1GT h2 {
      margin-bottom:0;
      text-align: center;
  }

  .banner1GT h3 {
      width:90%;
  }

  .banner2GT h1 {
      font-size: xx-large;
  }

  .banner2GT h3 {
      margin:auto;
  }

  .banner2GT img {
      min-width: none;
      max-width: 90%;
  }

  .banner2GT .doubleContainer {
      display: flex;
      flex-flow:column;
  }

  .banner2GT .leftContainer {
      width:95%;
      margin:auto;
      border:3px black solid;
  }

  .banner2GT .rightContainer {
      width:95%;
      margin: auto;
      border:3px black solid;
  }

  .banner3GT {
      text-align: center;
      font-size: large;
  }

  .banner3GT img {
      width:90%;
      max-width: 90%;
  }


  .product {
      border:solid 3px;
      width:95%;
  }

  .descriptionGT {
      width:100%;
      margin:auto;
  }

  .banner5GT {
      text-align: center;
  }

  .BGT {
      width:100%;
  }

  .banner2BGT img {
      width:90%;
  }

  .banner2BGT h3{
      text-align: center;
      margin-top:5px;
  }

  .banner3BGT .section section {
      width: 100%;
  }

  .banner3BGT .section {
      flex-flow:column;
  }

  .banner3BGT .section img {
      width:60%;
  }

  .banner5BGT {
      text-align: center;
      margin:auto;
  }

  .banner1OG {
      text-align: center;
  }

  .banner1OG ul {
      padding:0;
  }

  .banner1OG ul li{
      padding:0;
  }

  .banner1OG h3 {
      padding:0;
      margin:auto;
  }

  .banner3OG {
      text-align: center;
  }

  .banner3OG .figures {
      flex-flow:column;
  }

  .banner3OG .figures .figure {
      width:100%;
      margin-bottom:2rem;
  }

  .banner4OG .doubleContainer {
      flex-flow:column;
      text-align: center;
  }

  .banner4OG .leftContainer, .banner4OG .rightContainer {
      border:3px solid;
      width:100%;
      margin:auto;
      margin-top:0;
      margin-bottom: 3rem;
  }

  .banner4OG .leftContainer ul, .banner4OG .rightContainer ul {
      padding:0;
  }

  .banner5OG {
      text-align: center;
  }

  .banner1RV {
      text-align: center;
  }

  .banner1RV h2 {
      padding:0;
      margin:auto;
  }

  .banner1RV .mainPic {
      width: 90%;
      max-width: none;
      max-height: none;
      margin-top:2rem;
  }

  .banner1RV ul {
      list-style-type: none;
      padding: 0;
  }

  .banner1RV .leftContainer {
      width:95%;
      border:none;
  }

  .banner1RV .leftContainer li {
      border:3px solid;
      border-radius: 0px;
      margin:1rem 0rem 1rem 0rem;
      font-size: large;
  }

  .banner1RV .rightContainer {
      display: none;
  }

  .banner1RV .leftContainer h3 {
      padding:0;
      margin:auto;
  }

  .banner1RV .leftContainer p {
      width:100%;
      margin: 1rem 0rem 1rem 0rem;
      padding:0;
  }

  .banner2RV {
      text-align: center;
  }

  .banner2RV p, .banner2RV h3 {
      font-size:x-large;
      margin:auto;
      margin-top:1rem;
      padding:0;
      text-align: center;
  }

  .banner3RV {
      text-align: center;
      width:100%;
      font-size: large;
  }

  .banner3RV li {
      font-size: large;
  }

  .banner3RV .comparison {
      flex-flow:column;
  }

  .banner3RV .compareSection {
      border:3px solid;
      width:95%;
      text-align: center;
      font-size: large;
      margin:auto;
  }

  .banner4RV {
      text-align: center;
  }

  .banner4RV p {
      font-size: x-large;
      margin:auto;
      padding:0;
  }

  .banner4RV img {
      width:80%;
  }

  .banner4RV ol {
      font-size: large;
      padding:0px 0px 0px 10px;
  }
  .banner4RV ol li p {
      font-size:large;
  }

  .banner4RV .imagesRV {
      flex-flow:row;
  }

  .banner4RV .imagesRV img {
      width:50%;
      padding:0;
      border:1px solid;
  }

  .banner5RV {
      text-align: center;
  }

  .banner5RV p {
      font-size: large;
  }

  .banner6RV {
      text-align: center;
  }

  .banner6RV .imagesRV {
      flex-flow:column;
      width:100%;
  }

  .banner6RV .imagesRV img {
      width:90%;
      height:auto;
      margin-bottom:1rem;
  }

  .Victron img {
      width: 90%;
      height:auto;
  }

}