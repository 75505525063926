.historyStyle {
  flex-direction: column;
  justify-content: flex-start;
  margin-top:15px;
  margin-bottom:15px;
  background-color: rgba(42,42,42,0.75);
  border-radius: 0px;
  margin:auto;
  width:95%;
  padding-bottom:50px;
  margin-bottom:50px;
}

.historyStyle h1 {
  padding:20px;
  margin:10px auto 10px auto;
  border-radius: 0px;
  text-align: center;
  color:white;
}

.historyStyle p {
  line-height: 24px;
  width:90%;
  padding:20px;
  margin:10px auto 10px auto;
  border-radius: 0px;
  text-align: center;
  background-color: var(--box-background-color);
}

.recentJobTitle{
  text-align: center;
  color:white;
}

.job {
  display:flex;
  flex-flow:column;
  align-items: center;
  justify-content: center;
  height: 350px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  margin-bottom:30px;
  border-radius: 0px;
  border:5px solid var(--background-color);
}

.job a {
  text-decoration: none;
}

.jobName {
  height:20%;
  width:100%;
  border-top-right-radius:3px;
  border-top-left-radius: 3px;
  background-color: var(--secondary-color);
  color:var(--primary-color);
  display:flex;
  align-items: center;
  justify-content: center;
}

.job:hover {
  transform: scale(1.005);
}

.jobContent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height:80%;
  width:100%;
  background-color: var(--lighter-primary-color);
  border-radius: 0px;
  padding:10px;
  box-sizing: border-box;
}

.jobContent img {
  height: 100%;
  width: 35%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.jobDescription {
  width: 75%;
  text-align: center;
  box-sizing: border-box;
  padding:10px;
  background-color: var(--box-background-color);
  margin-left:20px;
}

.jobDescription h2 {
  text-decoration: underline;
}

.jobDescription .seeMore {
  border:solid 1px;
  float: right;
}

.info {
  display:flex;
  flex-flow:row wrap;
}

.stars{
  font-size: 50px;
  background-color: var(--lighter-primary-color);
  border-radius: 0px;
  padding:10px;
  text-decoration: none;
  border:solid 3px var(--secondary-color);
  margin:auto;
}


.article {
  margin-bottom:20px;
  background-color:var(--lighter-primary-color-half);

}

.jobArticle h1 {
  text-align: center;
}

.jobArticle button {
  border-radius:0px;
  margin-top: 10px;
  width:200px;
}

.jobArticle img {
  border-radius:0px;
  border:solid 3px;
  margin-left:15px;
}

.articleTitle {
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: var(--lighter-primary-color-half);
  height:5%;
  color:white;
  border-radius: 0px;
}

.navigateArticle {
  display:flex;
  flex-flow:row;
  justify-content: space-between;
  width:100%;
  margin-bottom:40px;
}

.navigateArticle nav {
  display:flex;
  flex-flow:row;
  align-items: center;
  justify-content: space-around;
  width:150px;
}

.navigateArticle a {
  font-size:30px;
  text-decoration: none;
}

.navigateArticle nav {
  background-color: aliceblue;
  border-radius: 5%;
}

.dot {
  color:var(--lighter-primary-color);
  transition:transform 0.3s ease-in-out;
  margin:auto;
}

.dot:hover {
  transform: scale(1.1);
}

.activeDot {
  color: var(--primary-color);
  font-size:40px;
  margin:auto;
}

@media only screen and (max-width:550px){
  .job{
    height:auto;
  }

  .jobContent{
    flex-flow:column;
  }

  .jobName{
    height:auto;
  }

  .jobName h2 {
    padding:0;
    margin:auto;
  }  
  
  .jobContent img {
    width:99%;
  }

  .jobDescription{
    width:99%;
    margin:auto;
  }

  .jobDescription h3 {
    margin:auto;
    padding:0;
  }

  .jobArticle button {
    width: 45%;
    border:solid 3px;
  }

  .navigateArticle nav {
    display:none;
  }

  .jobArticle .top .prev {
    position: fixed;
    bottom:5px;
  }

  .jobArticle .top .next {
    position: fixed;
    bottom:5px;
    right:5px;
  }

  .jobArticle .bottom {
    display:none;
  }

}