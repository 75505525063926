@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

* {
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

:root {
    --primary-color: rgb(42, 42, 42);           /* Dark Gray */
    --secondary-color: rgb(252, 175, 59);       /* Orange */
    --accent-color: rgb(255, 210, 59);          /* Lighter Orange for Contrast */
    --clickable-box-color: rgba(255, 210, 59, 0.15);
    --text-color: rgb(42, 42, 42);           /* Light Gray for Text on Dark Background */
    --background-color: rgb(42, 42, 42);        /* Slightly Lighter Gray for Background */
    --box-background-color: rgb(227,230,230);
    --background-color-grey: rgb(158,158,159);
    --box-color: rgb(234,234,234);
    --lighter-primary-color: rgb(80,80,80);
    --lighter-primary-color-half: rgba(80,80,80,0.75);
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #a0b262 #feeaad;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 20px;
  }

  *::-webkit-scrollbar-track {
    background: #feeaad;
    border-radius:15px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #a0b262;
    border-radius: 8px;
    border: 2px solid #5c340f;
  }

header{
    display:flex;
    flex-flow:column wrap;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
}

.headerContainer{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav ul {
    display:flex;
    flex-flow:row wrap;
    list-style-type: none;
}

ul a {
  text-decoration: none;
  color: white;
  padding: 10px;
  display: block;
  margin:3px;
  font-size:20px;
  transition: background-color 0.3s ease;
  color:white;
  border-radius: 0px;
}

nav ul a:hover {
  background-color: var(--secondary-color);
  color:black;
}


.active {
  background-color: var(--secondary-color);
  color:black;
}

.content {
  display:flex;
  margin:auto;
  width:90%;
}

footer {
  display:flex;
  flex-flow:column;
  margin:auto;
  align-items: center;
  justify-content: center;
  font-size:16px;
  color:var(--background-color);
  text-align: center;
  background-color: var(--background-color);
  padding-bottom:50px;
}

footer p {
  width:95%;
  margin:20px, 0px, 0px, 0px;
  padding:0;
  color:var(--box-background-color)
}

footer a {
  color: #e3e6e6;
}

body {
  color: var(--text-color);
  width:100%;
  background:var(--background-color);
}

.solarBackground {
  background-image: url("./components/solarPanelBackground.jpg");
  background-size:contain;
  background-repeat: no-repeat;
  background-position-x: center;
}

.logo {
    width:30%;
    max-width:300px;
    transition: transform 0.3s ease-in-out;
}

.logo:hover {
    cursor: pointer;
    transform:scale(1.015);
}


/* headerContainer styles */
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    /* Add other styles such as padding, background, etc. */
  }
  
  /* Default styles for nav links */
  .navbar-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  
  /* Default hidden state for mobile menu */
  .menu-button {
    display: none;
  }

  .privacyPopUp {
    position:fixed;
    bottom:0;
    border:solid;
    transform: translateX(-50%);
    left:50%;
    background-color: aliceblue;
    width:40%;
    display:flex;
    flex-flow:row;
    justify-content: space-between;
    align-items: center;
  }

  .privacyPopUp h3 {
    margin-left:10px;
  }

  .privacyPopUp .buttons {
    margin-right:5px;
  }

  .privacyPopUp button {
    border:solid 1px;
    margin-right:5px;
  }
  
  /* Media query for small screens */
  @media only screen and (max-width: 1040px) {
    .content {
      width:100%;
    }
    /* Styles for nav links */
    .navbar-links {
      position: fixed;
      top: 0%;
      right: -100%;
      width: 30%;
      background: #fff;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      transition: right 0.3s;
      z-index: 1000; /* Ensure the menu is above other content */
      border:solid;
      background-color: var(--background-color);
      border-radius:0px;
      padding-right:5%;
    }

    .navbar-links a {
        width:90%;
    }

  
    /* Styles for nav links when menu is open */
    .navbar-links.open {
      right: 0;
    }
  
    /* Styles for menu button */
    .menu-button {
      display: flex;
      border:solid white;
      font-size: x-large;
      border-radius: 0px;
      /* Add other styles for the button such as size, color, etc. */
    }

    .logo {
      width:70%;
    }

    .privacyPopUp {
      width:80%;
    }

  }

  @media only screen and (max-width: 550px) {
    .privacyPopUp{
      width: 100%;
    }

    .privacyPopUp h3 {
      font-size: small;
      padding:0;
      margin-left:3px;
    }

    .privacyPopUp button {
      width:80%;
      font-size: small;
      margin-right: 0;
    }

    .privacyPopUp .buttons {
      margin-right: 0;
      display:flex;
      width:80%;
    }

    .privacyPopUp .x {
      width:20%;
    }
  }
  