.shopContainer {
  width:100%;
  background-color: rgb(227,230,230);
  border-radius: 0px;
}

.iconContainer {
  display:flex;
  flex-flow:row;
  justify-content: flex-end;
  margin-top:20px;
  box-sizing:content-box;
}

.iconContainer img {
  width:3%;
  max-width: fit-content;
  height:50%;
  padding:10px;
  border:solid 1px;
  border-radius: 0px;
  margin:0px 5px 0px 5px;
  background-color: var(--box-background-color);
  transition: background-color 0.3s ease;
}

#cartIcon {
  position: fixed;
  width:5vh;
  height:5vh;
  right:15px;
  bottom:80px;
  z-index: 0;
}

.yourCartH2 {
  margin-left:5%;
}

.searchBar {
width:40%;
border-radius: 0px;
border:1px solid black;
font-size: 32px;
}

.hideSearchBar {
display:none;
}

.displaySearch {
position:absolute;
top:155px;
right:100px;
width:40%;
height:auto;
background-color: var(--box-background-color);
}

.searchProduct {
padding:1px;
text-align: center;
transition: background-color 0.3s ease;
border:solid;
}

.searchProduct:hover {
background-color: var(--accent-color);
cursor: pointer;
}

.iconContainer img:hover {
  cursor: pointer;
  background-color: var(--accent-color);
}

.advertisement {
  width:90%;
  margin:auto;
  display:flex;
  flex-flow:column;
  border:solid 3px;
  border-radius: 0px;
  margin-top:30px;
  text-align: center;
  background-color: var(--box-background-color);
}

.filterContainer {
  display:flex;
  flex-flow:row;
  margin:auto;
  width:90%;
}

.productFilters{
  width:20%;
  display:flex;
  flex-flow:column;
  text-align: center;
}

.productContainer {
display:flex;
justify-content: center;
flex-flow:column;
}

.page {
display:flex;
flex-flow: row;
justify-content: center;
}

.page button {
border:2px solid black;
border-radius: 0px;
padding:15px;
margin:10px;
background-color: var(--clickable-box-color);
}

#activePage {
background-color: var(--accent-color);
}

.seriesFilterH2 {
  margin-left:5%;
}

.radioFilter {
  background-color: white;
  border:solid 3px black;
  padding:10px;
  display:flex;
  flex-flow:row wrap;
  border-radius:0px;
  width:90%;
  margin:auto;
}

.radioFilterOptions {
  margin:auto;
  margin-top:5px;
  padding-bottom:2px;
  font-family: system-ui, sans-serif;
  font-weight: bold;
  line-height: 1;
  display: flex;
  width:30%;
  border:solid 1px black;
  border-radius:0px;
  align-items: center;
  background-color: var(--clickable-box-color);
  transition: background-color 0.3s ease;
}

.radioFilterOptions:hover {
  background-color: var(--accent-color);
  cursor: pointer;
}

.radioFilterOptions input[type='radio'] {
  width: 24px;  /* Size of the outer circle */
  height: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #000; /* Make border thicker for visibility */
  border-radius: 50%;
  outline: none;
  position: relative;
  background-color: transparent;
}

.radioFilterOptions input[type='radio']::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;  /* Smaller size of the inner circle */
  height: 10px;
  background-color: black;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0; /* Hide initially */
  transition: opacity 0.2s ease;
}

.radioFilterOptions input[type='radio']:checked::before {
  opacity: 1; /* Show only when checked */
}

.productContainer .popular {
  border-radius: 0px;
  border:solid 3px;
  padding:10px;
  margin:auto;
  margin-top:10px;
  width:50%;
  text-align: center;
  font-weight:600;
  font-size:large;
  text-decoration: none;
  background-color: var(--clickable-box-color);
  color:black;
  transition: background-color 0.3s ease;
}

.productContainer a:hover {
background-color: var(--secondary-color);
}

.productContainer .active {
background-color: var(--accent-color);
}

.productFilters a {
  border-radius: 0px;
  border:solid 3px;
  padding:5px;
  margin:5px;
  font-weight:600;
  font-size:large;
  text-decoration: none;
  background-color: var(--clickable-box-color);
  color:black;
  transition: background-color 0.3s ease;
}

.productFilters a:hover {
  background-color: var(--secondary-color);
}

.productFilters .active {
  background-color: var(--accent-color);
}

.productForSale {
  display:flex;
  flex-flow:row;
  border:solid 3px;
  border-radius: 0px;
  width:90%;
  margin:auto;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height:240px;
  margin-bottom:10px;
}

.productImageContainer {
display:flex;
justify-content: center;
width:30%;
max-height:90%;
box-sizing: border-box;
}

.productImageContainer img {
  max-width:100%;
  max-height:240px;
  border-radius: 0px;
}

.productDescription {
width:40%;
}



.productForSale .optionsForSale {
  display:flex;
  flex-flow:column;
  width:30%;
}

.optionsForSale button{
  margin:5%;
  border:3px solid;
  width:auto;
  border-radius: 0px;
}

.dollarSign {
  vertical-align: super;
  font-size: medium;
}

.price {
  font-size:xx-large;
  margin:auto;
}

.productDescription .doubleContainer {
display:flex;
flex-flow:row;
}

.left {
  margin:auto;
  width:50%;
}

.right {
  margin:auto;
  width:40%;
  text-align: right;
}

.triSection {
  display:flex;
  flex-flow:row;
  width:90%;
  margin:auto;
  justify-content: space-evenly;
}

.triSection .leftInTri .productMainImage{
box-sizing: border-box;
height:500px;
display:flex;
justify-content: center;
background-color:transparent;
}

.triSection .leftInTri .productMainImage img{
max-height:500px;
max-width: 90%;
margin:auto;
border-radius: 0px;
}

.leftInTri .productSubImage {
  display:flex;
  flex-flow:row;
}

.leftInTri .productSubImage .productSubImageContainer img {
  margin:auto;
  max-height:200px;
  max-width:100%;
}

.leftInTri .productSubImage .productSubImageContainer{
display:flex;
justify-content: center;
max-width: 33%;
border:solid;
padding:5px;
border-radius:0px;
background-color: var(--box-background-color);
transition:background-color 0.3s ease-in-out;
}

.leftInTri .productSubImage .productSubImageContainer:hover {
  background-color: var(--secondary-color);
}

.leftInTri .productSubImage .activeImage {
  background-color: var(--accent-color);
}

.triSection .leftInTri {
  width:45%;
}

.triSection .midInTri {
  width:40%;
  box-sizing:content-box;
  padding-left:30px;
}

.triSection .rightInTri {
  width:25%;
  height:225px;
  border:solid 3px;
  border-radius: 0px;
  background-color: var(--box-background-color);
  box-sizing:content-box;
  margin-left:30px;
  display:flex;
  flex-flow:column;
  justify-content: space-evenly;
  align-items: center;
}

.triSection .rightInTri select {
  width:60%;
  font-size: large;
  text-align: center;
}

.ProductPage .backButton, .cartBackButton {
  border:3px solid;
  border-radius: 0px;
  background-color:var(--box-background-color);
  width:15%;
  margin: 15px 0px 0px 0px;
  transition:background-color ease 0.3s;
}

.ProductPage .backButton {
  margin-left:5%;
}

.ProductPage .backButton:hover {
  background-color: var(--accent-color);
}

.addToCart {
  background-color: var(--accent-color);
}

.ProductPage .addToCart {
  background-color: var(--accent-color);
  border:3px solid;
  border-radius: 0px;
  display:flex;
  width:95%;
  justify-content: center;
  margin:auto;
  text-align: center;
  font-size: medium;
}

.cartCheckOutContainer {
  display:flex;
  flex-flow:row;
}

.cart{
  width:80%;
  display:flex;
  flex-flow:column;
  margin-left:0;
  margin-top:20px;
  align-items: flex-start;
  height:auto;
}

hr {
  border: none;
  border-top: 2px solid #8c8c8c; /* Black line */
  margin: 10px 0; /* Add some space around the hr */
  width:95%;
  margin:auto;
}

.cartHeader, .cartFooter{
  display:flex;
  flex-flow:row;
  justify-content: space-between;
  width:95%;
  margin:auto;
}

.cartItem {
  display:flex;
  flex-flow:row;
  align-items: center;
  width:100%;
}

.cartDes {
  width:50%;
  margin-left:5%;
  margin-right:5%;
}

.cartImage {
  width:20%;
  display:flex;
  justify-content: center;
}

.cartItem img {
  max-width:100%;
}

.cartPrice {
  text-align: center;
  width:20%;
}

.popup {
  position: fixed;
  top: -100%; /* Start off-screen */
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--box-background-color);
  padding: 20px;
  border: 1px solid black;
  border-radius: 0px;
  text-align: center;
  transition: top 0.5s ease-in-out; /* Smooth transition for the slide */
}


@keyframes slideIn {
  from { top: -100%; }
  to { top: 10%; }
}

@keyframes slideOut {
  from { top: 10; }
  to { top: -100%; }
}

.popup.show {
  animation: slideIn 0.5s forwards;
}

.popup.hide {
  animation: slideOut 0.5s forwards;
}

.cartItemOptions {
  display:flex;
  flex-flow:row;
  width:100%;
  justify-content: space-evenly;
  align-items: center;
}

.cartItemOptions select {
  text-align: center;
  padding:3px;
  border:2px solid;
  border-radius: 0px;
  font-size: large;
}

.cartItemOptions span {
  font-size:large;
  margin-right:5px;
}

.delete {
  border:2px solid;
  border-radius: 0px;
}

.cartMoreInfo:hover {
  cursor: pointer;
  color:rebeccapurple;
}

.checkOutContainer {
  position:fixed;
  right:5%;
  background-color: var(--box-background-color);
  border:solid 3px;
  border-radius: 0px;
  width:15%;
  height:fit-content;
  padding:10px;
  margin-top:20px;
  padding-bottom:30px;
  display:flex;
  flex-flow:column;
  align-items: flex-end;
}

.checkoutButton {
  border:3px solid;
  border-radius: 0px;
  background-color: var(--accent-color);
  transition: background-color 0.3s ease;
  width:90%;
  margin:auto;
}

.checkoutButton:hover {
  background-color: var(--secondary-color);
}

.productNameMobile {
  display:none;
  width:90%;
  margin:auto;
  margin-top:20px;
}

.productNameDesktop {
  display:block;
}

.filterContainerMobile{
  display:none;
}

.toggleSwitch {
  display:none;
}

.radioFilterMobile{
  display:none;
}

.hideSeriesMenu{
  display:none;
  visibility:hidden;
}

.rightInTriMobile {
  display:none;
}

.checkoutPage {
  display:flex;
  flex-flow:row;
}

.checkoutPage .leftContent {
  width:60%;
}

.checkoutPage .rightContent {
  width:35%;
  border:solid;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  padding-bottom:50px;
  margin-top:50px;
  margin-bottom:50px;
  max-height:600px;
  display:flex;
  flex-flow:column;
}

.checkoutPage .rightContent p {
  margin:auto;
  margin-top:5px;
  padding:5px;
}

.checkoutPage .rightContent > h2 {
  width:90%;
  margin:auto;
}

.checkoutPage .divider2, .checkoutPage .divider3 {
  display:flex;
  flex-flow:row;
}

.checkoutPage label {
  display:flex;
  flex-flow:column;
  margin-bottom:5px;
}

.checkoutPage input, .checkoutPage select {
  height:32px;
  font-size:large;
}

.checkoutPage .divider2 label {
  width:50%;
}

.checkoutPage .divider3 label {
  width:33.3%;
}

.checkoutPage .divider3 label select {
  height:100%;
}

.creditCardBanner {
  display:flex;
  flex-flow:row;
  align-items: center;
  width:90%;
  margin:auto;
}

.creditCardBanner h2 {
  margin-right:10%;
}

.creditCardBanner img {
  width:10%;
}

.shippingForm {
  display:flex;
  flex-flow:column;
  width:90%;
  margin:auto;
}

.payment {
  box-sizing: border-box;
}

.payment >h2 {
  margin-left:5%;
}

.paymentForm{
  width:90%;
  margin:auto;
  margin-bottom:50px;
  display:flex;
  flex-flow:column;
}

.expiration {
  display:flex;
  flex-flow:row;
}

.expiration select {
  margin-right:10px;
  margin-top:5px;
}

.checkoutPage button{
  border:solid;
  width:60%;
  margin:auto;
  margin-top:10px;
  margin-bottom:10px;
  background-color: var(--accent-color);
  border:none;
}

.checkoutPage button:hover{
  background-color: var(--secondary-color);
}

.paymentForm .billingAddressCheck {
  display:flex;
  flex-flow:row;
  align-items: center;
  margin-top:20px;
}

.billingAddressCheck input {
  width:24px;
}

.checkOutEmail form {
  display:flex;
  flex-flow:column;
}

.checkOutEmail label {
  width:90%; 
  display:flex;
  flex-flow:row;
  margin:auto;
  align-items: center;
}

.checkOutEmail label input {
  width:75%;
  margin-left:10px;
}

.cartProductSummary{
  border:solid;
  width:95%;
  margin: auto;
  max-height:300px;
  overflow: auto;
}

.cartProductSummaryLine{
  display:flex;
  flex-flow:row;
}

.cartProductSummaryLine span {
  font-weight:800;
  font-size: large;
}

.cartProductSummaryLine .left {
  width:15%;
  margin-left:5px;
}

.cartProductSummaryLine .middle {
  width:70%;
}

.cartProductSummaryLine .right {
  width:15%;
  margin-right:5px;
}

.rightContent button {
  margin:auto;
  margin-top:20px;
}

.noShipping {
  text-decoration: line-through;
}

.confirmationPage {
  display: flex;
  flex-flow:row;
  justify-content: space-between;
  width:90%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.confirmationPage .left {
  width:60%;
  border:solid 1px;
  box-sizing: border-box;
  padding:15px;
  background-color: rgba(255, 210, 50, 0.5);
}

.confirmationPage .right {
  width:35%;
  text-align: center;
}

.confirmationPage .right img {
  max-width: 100%;
}

.moreSorting {
  width:100%;
  margin-top:5px;
  display:flex;
  flex-flow:column;
  align-items: flex-end;
}

.moreSorting button {
  width:30%;
  border:solid 1px;
}

.moreSorting select {
  font-size: 18px;
}

.ad {
  display: flex;
  flex-flow:column;
  justify-content: center;
}

@media only screen and (max-width: 1350px){
  .productNameMobile {
    display:flex;
  }

  .productNameDesktop {
    display:none;
  }

  .displaySearch{
    top:170px;
    right:110px;
  }
}

@media only screen and (max-width: 1040px){
  .checkOutContainer{
    right:0%;
  }

  .displaySearch{
    right:12%;
  }

  .moreSorting button {
    width:40%;
  }
}

@media only screen and (max-width: 750px){
  .displaySearch{
    top:160px;
    right:15%;
  }

  .searchProduct {
    font-size: small;
  }

  .iconContainer img {
    width:5%;
  }

  .filterContainer{
    display:none;
  }

  .filterContainerMobile{
    display:flex;
    flex-flow:column;
    justify-content: space-between;
    width:90%;
    margin:auto;
  }

  .categoriesMobile {
    display:flex;
    flex-flow:row;
    justify-content: space-between;
    width:90%;
    margin:auto;
  }

  .categoryMobile1 {
    display:flex;
    flex-flow:row;
    justify-content: space-between;
    width:90%;
    margin:auto;
    padding-left:2.5%;
    padding-right:2.5%;
    background-color: var(--box-color);
  }

  .categoryMobile2 {
    display:flex;
    flex-flow:row;
    justify-content: space-between;
    width:90%;
    margin:auto;
    padding-left:2.5%;
    padding-right:2.5%;
    background-color: var(--background-color-grey);
  }

  .hideCategoryMenu {
    opacity: 0;
    max-height:0;
    visibility: hidden;
  }

  .categoryMenu {
    display:flex;
    flex-flow:column;
    justify-content: flex-start;
    width:90%;
    margin:auto;
    opacity:1;
    transition: opacity 0.3s ease;
  }

  .categoryOpenMenu {
    display:flex;
    flex-flow:column;
    width:90%;
    margin:auto;
    opacity: 1;
    transition: opacity 0.3s ease;
    background-color: var(--box-color);
  }

  .categoryOpenMenu a {
    padding:6px;
    border:solid 1px var(--primary-color);
    margin-bottom:4px;
    text-decoration: none;;
    color:var(--primary-color);
    font-weight:bold;
  }

  .advertisement{
    width:80%;
    
  }

  .advertisement h2{
    font-size: large;
    width:90%;
    margin:auto;
    padding:10px;
  }

  .page button {
    border:1px solid black;
    margin: 5px;
  }

  .radioFilter{
    display: none;
    height:0;
  }

  .seriesFilterTitle{
    display:flex;
    flex-flow:row;
    justify-content: space-between;
    width:81%;
    margin:auto;
  }

  .seriesFilterH2{
    margin:auto 0 auto 0;
  }

  .toggleSwitch {
    display:block;
  }

  .hideSeriesMenu {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
  }

  .radioFilterMobile {
    display:flex;
    flex-flow:column;
  }

  .radioFilterMobile .radioFilterOptions {
    width:70%;
    margin:auto;
  }

  .cartItem img{
    max-width:90%;
  }

  .cart{
    width:75%;
  }

  .checkOutContainer{
    width:20%;
    padding:5px;
  }

  .cartDes {
    font-size: small;
    width:100%;
  }

  .cartItemOptions select {
    padding:0;
    border:1px solid;
  }

  .delete {
    border:1px solid;
    padding:5px;
  }

  .checkOutContainer h2 {
    font-size: large;
    padding:10px;
    margin: auto;
  }

  .backToTop{
    display:none;
  }

  .checkoutButton{
    font-size:small;
    margin:auto;
    width:90%;
  }

  .checkoutPage {
    flex-flow:column;
  }

  .checkoutPage .leftContent {
    width: 90%;
    margin:auto;
  }

  .checkoutPage .rightContent {
    width:90%;
    margin:auto;
    box-shadow: none;
    margin-bottom:50px;
  }

  .confirmationPage {
    flex-flow:column;
  }

  .confirmationPage .left {
    width:90%;
  }

  .confirmationPage .right {
    width:90%;
  }

  .moreSorting button {
    width:50%;
  }

  .ad {
    font-size: small;
    text-align: center;
  }
}

@media only screen and (max-width:550px){
  .displaySearch{
    right:20%;
    top:150px;
  }

  .iconContainer img {
    width: 7%;
  }

  .productForSale{
    height:200px;
  }

  .productDescription{
    font-size: small;
    width: 50%;
  }

  .price {
    font-size:x-large;
  }

  .productForSale .optionsForSale button {
    padding: 5px 0px 5px 0px;
    font-weight:normal;
    border:1px var(--primary-color) solid;
  }

  .productForSale .optionsForSale{
    width:20%;
  }

  .cartDes {
    font-size: small;
    width:100%;
  }

  .cart{
    height:auto;
    width:90%;
    margin:auto;
    margin-top:5%;
  }

  .checkoutButton{
    width:100%;
    border:none;
    margin: 5% auto 5% auto;
  }

  .cartPrice {
    margin-right:3%;
  }

  .cartItemOptions{
    align-items: flex-end;
  }

  .cartPrice {
    font-size: small;
  }

  .cartCheckOutContainer{
    flex-flow:column;
  }

  .checkOutContainer{
    position:relative;
    right:auto;
    margin:auto;
    border:none;
    width:80%;
  }

  .checkOutContainer h2 {
    display: none;
    visibility: hidden;
  }

  .cartBackButton {
    border:1px solid;
    width:20%;
  }


  .triSection{
    flex-flow:column;
  }

  .triSection .leftInTri {
    width:100%;
  }

  .triSection .leftInTri .productMainImage {
    height:300px;
    width:100%;
  }

  .triSection .leftInTri .productMainImage img {
    max-height:300px;
    max-width:100%;
  }

  .triSection .midInTri {
    width:100%;
    margin:auto;
    padding:0;
  }

  .productNameDesktop{
    display:block;
    font-size:x-large;
  }

  .triSection .rightInTri{
    display:none;
    visibility: hidden;
  }

  .leftInTri .productSubImage .productSubImageContainer img {
    max-height:100px;
  }

  .leftInTri .productSubImage{
    justify-content: center;
    margin-top:5px;
  }

  .rightInTriMobile{
    display:flex;
    flex-flow:column;
  }

  .rightInTriMobile h2 {
    font-size:xx-large;
    margin-top: 10px;
    margin-bottom:0;
  }

  .rightInTriMobile select {
    margin:10px;
    font-size:x-large;
  }

  .rightInTriMobile .addToCart {
    border:none;
    padding:15px;
    margin:10px;
  }

  .ProductPage .backButton {
    width:25%;
  }

  .moreSorting button {
    width:60%;
    border:solid 1px;
  }

}