.overall-container {
  width:100%;
}

.banner{
  display:flex;
  flex-flow:row wrap;
  width:100%;
  justify-content: space-between;
  align-items: center;
  margin:auto;
  padding:0;
  height:300px;
}

.News {

  width:100%;
  background-color: rgba(42, 42, 42, 0.8);
  text-align: center;
}

.News h1 {
  color:white;
  font-size:xxx-large;

}

.News .doubleContainer {
  display:flex;
  flex-flow:row;
  justify-content: space-evenly;
  align-items: center;
}

.News .doubleContainer .leftContainer {
  width:40%;
  border:none;
}

.News .doubleContainer .rightContainer {
  width:50%;
  border:3px solid var(--box-background-color);
  border-radius: 0px;
  display:flex;
  flex-flow:column;
  justify-content: center;
  align-items: center;
  margin-top:20px;
  margin-bottom:20px;
  background-color: var(--box-background-color);
}

.News .doubleContainer .rightContainer h1, .News .doubleContainer .rightContainer h2 {
  width:90%;

}

.News img {
  width:100%;
  border-radius:0px;
  border:solid 3px var(--box-background-color);
}

.News .doubleContainer .rightContainer .smallIcon {
  width:50px;
  border-radius: 0px;
  transition:transform 0.3s ease-in-out;
  border:none;
}

.smallIcon:hover {
  transform:scale(1.1);
  cursor: pointer;
}

.Carrousel {
  display:flex;
  flex-flow:column;
  align-items: flex-end;
  justify-content: center;
}

.carousel {
  width:100%;
  display:flex;
  flex-flow:row;
  align-items: center;
  justify-content: center;
  height: 400px;
  border-radius: 0px;
  border:solid 3px var(--box-background-color);
}

.carousel-content {
  width:100%;
}

.carousel-container {
    display: flex;
    flex-flow:column wrap;
    align-items:stretch;
    width:100%;
    height:100%;
    overflow: hidden;
    align-items: flex-end;
    background-color: var(--box-background-color);
}

.carousel-container h1{
  margin-top:0px;
  width:fit-content;
  color:var(--box-background-color);
  padding:10px;
  margin-bottom: 10px;
  margin-right:10px;
  background-color: rgba(42, 42, 42,0.5);
  border-radius: 0px;
}

.carousel-container button {
  margin-bottom:10px;
  margin-right:10px;
  border-radius: 0px;
  width:250px;
  font-weight:800;
  height:75px;
  font-size:25px;
}

button {
  background-color: var(--box-background-color);
  color:var(--background-color);
  border: none;
  padding: 10px;
  cursor: pointer;
  font-weight:bold;
  font-size:18px;
  transition: background-color 0.2s ease;
  border:3px solid var(--box-background-color);
}


button:hover {
  background-color: var(--secondary-color);
  border-color:var(--text-color);
  color:black;
}

.leftButton {
  float:left;
  height:100%;
  max-width: 50px;
  width:5%;
  font-weight:bold;
  border-top-left-radius:3px;
  border-bottom-left-radius:3px;
  border:solid 1px var(--box-background-color);
}

.rightButton {
  float:right;
  height:100%;
  max-width: 50px;
  width:5%;
  font-weight:bold;
  border:1px solid var(--box-background-color);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.offer-banner {
  width:100%;
  display:flex;
  flex-flow:column;
  align-items: center;
  margin:0;
  padding:0;
  text-align: center;
  color:var(--box-background-color);
}

.offer-banner h1, .offer-banner h2 {
  z-index:100;
  padding:5px;
  background-color: rgba(42, 42, 42, 0.8);
  margin:10px auto;
  color:white;
}

.offer-banner .infos {
  display:flex;
  flex-flow:row;
  border-radius: 0px;
  margin:auto;
  padding:0;
}

.offer-banner .infos .info-container{
  border:none;
  border-radius: 0px;
  padding:10px;
}

.offer-banner .infos .info-container:hover {
  cursor:auto;
}

.offer-banner .infos .info-container img {
  width:100%;

}

.about-banner {
  display:flex;
  flex-flow:row wrap;
  width:100%;
  margin:auto;
  padding:0;
  align-content: flex-start;
  background-color: rgb(158,158,159);
  border:3px solid var(--box-background-color);
  border-radius: 0px;
}

.about-banner h1 {
  color:var(--text-color);
  text-align: center;
  width:100%;
  font-size:40px;
}

.about-container {
  width:100%;
  display:flex;
  flex-flow:row wrap;
  justify-content: space-around;
}

.link {
  display:flex;
  padding:10px;
  justify-content: flex-end;
  width:100%;
  margin-top: 50px;
}

.link button {
  width:250px;
  font-weight:800;
  height:75px;
  font-size:20px;
  border-radius: 0px;
}

.info-container{
  display:flex;
  flex-flow:column;
  align-items:center;
  max-width:500px;
  width:25%;
  min-width:150px;
  border-radius:0px;
  background-color: rgba(42, 42, 42, 0.8);
  color:white;
  padding:20px;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease;
  aspect-ratio: 10 / 10;
  font-size: x-large;
  text-align: center;
}

.info-container:hover {
  transform: scale(1.01);
  color:var(--text-color);
  background-color: var(--secondary-color);
  cursor: pointer;
}

.info-container img{
  width:100%;
  height:100%;
  max-height:300px;

  border-radius:0px;
}

.info {
  margin-top:10px;
  margin-bottom:10px;
  text-decoration: underline;
  font-size:x-large;
  text-align: center;
  font-weight:bold;
}

.logoH1 {
  color:white;
  margin:auto;
}

.logoMainContainer {
  flex-flow:column;
  text-align: center;
  background-color: var(--lighter-primary-color);
  width:100%;
  height:auto;
}

.logoMainContainer .logoSubContainer {
  display:flex;
  justify-content:center;
  margin-top:10px;
  margin-bottom:10px;
  height:100%;
}

.logoImageContainer {
  box-sizing:content-box;
  background-color: aliceblue;
  height:100px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.logoImageContainer img {
  max-height: 90px;
  max-width:80%;
  padding:10px;
  margin:auto;
}

.slick-slide {
  max-height:100px;
  box-sizing:border-box;
  padding:0px 10px;
}

.slick-list {
  width:90%;
}

.sectionContainer {
  margin: 10px 0px;
  width:100%;
}

.productsBanner {
  display:flex;
  flex-flow:column;
  align-items: center;
  height:400px;
  max-height: 400px;
  border-radius: 0px;
  border:3px solid var(--box-background-color);
  background-image: url('../children-about/images-about/CarouselContent1.jpg');
  background-size: cover; /* Resize the background image to cover the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.productsBanner h1{
  margin-top:0;
  background-color: rgba(42, 42, 42, 0.9);
  color:var(--box-color);
  border-radius: 0px;
  padding:10px;
}

.productsBanner button {
  border-radius: 0px;
  width:40%;
  font-weight:800;
  height:75px;
  font-size:25px;
}

.getAQuote {
  display:flex;
  flex-flow:column;
  align-items: flex-end;
  height:400px;
  border-radius: 0px;
  border:3px solid var(--box-background-color);
}

.getAQuote h1{
  margin-top:0;
  background-color: rgba(42, 42, 42, 0.5);
  color:var(--box-color);
  margin-right:5%;
  border-radius: 0px;
  padding:10px;
}

.getAQuote button {
  margin-right:5%;
  border-radius: 0px;
  width:250px;
  font-weight:800;
  height:75px;
  font-size:25px;
}

.education {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  resize:both;
  display:flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-flow:column;
  height:400px;
  border-radius: 0px;
  border:solid 3px var(--box-background-color);
}

.education h1 {
  background-color: rgba(255,255,255,0.75);
  color: black;
  margin-right:5%;
  padding:10px;
  border-radius: 0px;
}

.education button {
  margin-right: 5%;
  border-radius: 0px;
  width:250px;
  font-weight:800;
  height:75px;
  font-size:25px;
}

.media {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-flow:column;
  width:100%;
  border-radius: 0px;
}

.media h1 {
  color:var(--box-background-color);
}

.media img {
  height:100px;
  width:100px;
  transition: transform 0.3s ease-in-out;
}

.media img:hover {
  transform: scale(1.05);
}

.media .icons {
  display:flex;
  flex-flow:row;
  justify-content: center;
}

.media .icons a {
  margin:5%;
}

.privacy {
  background-color: aliceblue;
  max-width:90%;
  margin:auto;
}

.privacy pre {
  white-space: pre-wrap; /* Allows the text to wrap */
  word-wrap: break-word; /* Breaks long words */
  overflow: auto; /* Adds scrolling if necessary */
  max-width: 100%; /* Ensures the pre element does not exceed container width */
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.privacy .navigation {
  display:flex;
  flex-flow:column;
  align-items: center;
  border:solid 1px;
  width:9%;
  position:fixed;
  right:0.5%;
  top:20%;
  background-color: aliceblue;
  padding-bottom:20px;
}

.privacy .buttons{
  display:flex;
  flex-flow: column;
  align-items: center;
  width:100%;
}

.privacy .navigation button {
  border:solid 1px;
  width:80%;
}

@media only screen and (max-width:1100px) {
  .privacy .navigation {
    bottom:0;
    top:auto;
    width:100%;
  }

  .privacy {
    max-width:100%;
  }

  .privacy .buttons {
    display:flex;
    flex-flow:row;
    width:100%;
  }

}

@media only screen and (max-width:955px) {
  .offer-banner h1 {
    font-size: x-large;
  }

  .offer-banner .infos .info-container {
    padding:5px;
    min-width: 100px;
  }

  .offer-banner h1, .offer-banner h2 {
    padding:5px;
  }


}

@media only screen and (max-width:550px) {

  .offer-banner h1, .offer-banner h2 {
    padding:2px;
  }

  .offer-banner .infos {
    flex-flow:column;
    width:100%;
  }

  .offer-banner .infos .info-container {
    flex-flow:row;
    width:95%;
    height:fit-content;
    max-height:150px;
    min-width: none;
    padding:0;
    margin: auto;
  }

  .offer-banner .info {
    text-align: center;
    margin: auto;
  }

  .offer-banner img {
    width:20%;
    height:auto;
    min-width: 0;
    min-height: 0;
    max-width: 50%;
  }

  .News h1 {
    font-size:xx-large;
    padding:0;
    margin:0;
  }
  
  .News .doubleContainer {
    flex-flow:column;
  }

  .News .doubleContainer .leftContainer {
    width:90%;
  }

  .News .doubleContainer .rightContainer {
    width:90%;
    margin-top:0;
  }

  .leftButton {
    position:absolute;
    height:400px;
    background-color:transparent;
    border:none;
    left: 1.5em;
  }

  .rightButton {
    position:absolute;
    height:400px;
    background-color:transparent;
    border:none;
    right: 1.5em;
  }

  .leftButton:hover {
    background-color: transparent;
  }

  .rightButton:hover {
    background-color: transparent;
  }

  .carousel-container h1 {
    margin-left:40px;
    margin-right:40px;
    padding: 5px 0px 5px 0px;
    text-align: center;
  }
  
  .info-container{
    width:80%;
    margin-bottom:10px;
  }

  .Carrousel {
    box-sizing:border-box;
    border-radius:0px;
    border:solid var(--box-background-color) 5px;
    align-items: center;
    justify-content: flex-end;
  }

  .carousel {
    border:none;
    background-color:transparent;
    width:102%;
  }

  .carousel-container {
    background-color: transparent;
  }

  .carousel-content h1 {
    font-size: x-large;
    text-align: center;
  }

  .slick-slide {
    max-height:80px;
    padding: 0px 5px;
  }

  .logoImageContainer img {
    max-height: 70px;
    max-width:70%;
    padding:10px;
  }

}
  
@media (max-width: 768px) {
    .carousel-container {
      flex-direction: column;
    }
  
    button {
      margin: 5px;
    }
}