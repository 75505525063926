.container {
    width:100%;
}

.contact h2 {
  z-index:100;
  color:white;
  background-color: rgba(42,42,42,0.8);
  padding:10px;
  width:fit-content;
  margin-left:auto;
  margin-right:auto;
}

.office {
    display:flex;
    flex-flow:row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5%;
    border:3px solid var(--background-color);
    background-color:var(--box-background-color);
    padding:10px;
    border-radius: 0px;
    font-size:x-large;
    box-sizing:border-box;
}

.office img {
    height:100%;
    border-radius: 0px;
    width:35%;
}

.officeContact {
    border:5px dotted var(--text-color);
    border-radius: 0px;
    width:40%;
    text-align: center;
}

.officeContact p {
    margin:0;
    padding:2%;
}

.list {
    display:flex;
    flex-flow:column;
    justify-content: space-around;
    align-items: flex-start;
    width:100%;
}

.person {
    box-sizing: border-box;
    text-align: center;
    display:flex;
    flex-flow:row;
    border:solid 3px var(--background-color);
    background-color:var(--box-background-color);
    border-radius:0px;
    width:100%;
    justify-content: space-evenly;
    align-items: center;
    padding:20px;
    margin-bottom:20px;
}

.person .description {
  display:flex;
  flex-flow:column;
}

.person img {
  max-width: 200px;
  border-radius: 0px;
}

.list .description {
    width:60%;
}

@media only screen and (max-width:550px) {

    .office {
        flex-flow:column;
        height:auto;
    }

    .office img {
        width:100%;
        margin-bottom:1rem;
    }

    .officeContact {
        width:90%;
    }

    .person {
        flex-flow:column;
        height:auto;
    }

    .person img {
        width:40%;
    }

    .list .description {
        width:90%;
    }
}
